<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Мои контракты</h1>
    <app-cells position="start">
      <router-link :to="{ name: 'contracts-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить контракт</span>
      </router-link>
    </app-cells>
    <app-table-head
        @update-limiter="onUpdateLimiter"
        :count="table.items.length"
        :page="pagination.page"
        :all="pagination.count"
    />
    <app-cells>
      <app-select
          @search="onFilterSearch"
          noDrop
          label="id"
          placeholder="Введите запрос"
          class="select select--minw"
      />
    </app-cells>
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    />
    <paginate
        v-if="pagination.pages > 1"
        v-model="pagination.page"
        :page-count="pagination.pages"
        :clickHandler="onClickPagination"
        :prev-text="'<'"
        :next-text="'>'"
        :container-class="'pagination'"
        :page-class="'pagination__item'"
        :page-link-class="'pagination__link'"
        :prev-class="'pagination__prev'"
        :next-class="'pagination__next'"
        :next-link-class="'pagination__link-next'"
        :prev-link-class="'pagination__link-prev'"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'ContractsList',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'name', 'kosgu', 'main_price', 'city', 'department', 'executor', 'date_end'],
        options: {
          headings: {
            id: 'ID',
            name: 'Наименование объекта закупки',
            kosgu: 'КОСГУ',
            main_price: 'Начальная (максимальная) цена контракта',
            city: 'Город',
            department: 'Подразделение',
            executor: 'Исполнитель',
            date_end: 'Планируемый срок заключения договора / оказания услуги / поставки товара',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => 'table-default__row',
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      status: '',
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(page = 1, page_size = 20, search = '') {
      this.$store.dispatch('contracts/GET_LIST', { page, page_size, search })
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
      loading(false)
    }, 350),
    onRowClick(row) {
      this.$router.push({name: 'contracts-view', params: {id: row.row.id}})
    },
  }
}
</script>
